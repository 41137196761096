import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import { AccountContext } from "../AccountValidation/Account";
import "./Login.css";

export default function Login({ sendValueToLoginMain }) {
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAlert, setPasswordAlert] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accessLevel, setAccessLevel] = useState("not allow");
  const [modal, setModal] = useState(false);
  const [userListInfo, setUserListInfo] = useState(null);
  const [userGroupInfo, setUserGroupInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xbdv2gldkojdd4hfk35srvru2m0yithf.lambda-url.ap-northeast-1.on.aws/');
        setUserListInfo(response.data['user_list']);
        setUserGroupInfo(response.data['user_group_info']);
        console.log("response", response.data)
      } catch (error) {
        console.error('Error fetching data from Lambda:', error);
      }
    };

    fetchData();
  }, []);

  const { authenticate } = useContext(AccountContext);

  const callbackURL = process.env.REACT_APP_COGNITO_CALLBACK_URL;

  const updateSignUpValue = () => {
    sendValueToLoginMain("1");
  };

  const onSubmitForgotPassword = () => {
    sendValueToLoginMain("2");
  };

  const toggleModle = () => {
    setModal(!modal);
  };

  const userList = []
  const onSubmitLogin = async (event) => {
    console.log("login");
    event.preventDefault();
    if (email === "" && password === "") {
      setEmailAlert("Please enter Email Id");
      setPasswordAlert("Please enter password");
    } else if (email === "" && password !== "") {
      setEmailAlert("Please enter Email Id");
      setPasswordAlert("");
    } else if (!email.includes("@kpit.com") && password === "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("Please enter password");
    } else if (!email.includes("@kpit.com") && password !== "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("");
    } else if (email.includes("@kpit.com") && password === "") {
      setEmailAlert("");
      setPasswordAlert("Please enter password");
    } else {
      console.log("User restriction");
      // console.log("response data userGroupInfo", userGroupInfo);
      // console.log("response data userListInfo", userListInfo);
      // Check if the user present in cognito user pool
      if(userListInfo.includes(email)){
        console.log("user present")
        // Check if the email is present in any group members
        let accessApproved = false;
        for (const groupName in userGroupInfo) {
          const group = userGroupInfo[groupName];
          const groupMembers = group.groupMembers;
          for (const member of groupMembers) {
            if (member.email === email) {
              accessApproved = true;
              break;
            }
          }
          if (accessApproved) break;
        }
        if (accessApproved) {
          console.log('Access approved');
          authenticate(email, password)
            .then(async (data) => {
              // Check if data is an object
              if (typeof data === 'object') {
                // Iterate through each property of the object
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        var value = data[key];
                        console.log(key + ':', value); // Log each property and its value
                    }
                }
              }
              localStorage.setItem('email localStorage', email);
              sessionStorage.setItem('email sessionStorage', email);
              localStorage.setItem('myData', JSON.stringify(data));
              sessionStorage.setItem('myData', JSON.stringify(data));
              console.log('sending data......1');
              window.location.href = callbackURL;
              // Open the URL in the browser
              // window.open(callbackURL, '_blank');
              await sendDataToDotnetApp(data);              
            })
            .catch((err) => {
              console.error("Failed to login", err);
              setErrorMessage(err.message);
            });
        } else {
          console.log('Access rejected');
          setErrorMessage("Access denied. Please contact admin to assign user role");
        }
      } else {
        console.log("user not present")
        setErrorMessage("User not present. Please sign-up");
      }
    }
  };

  const sendDataToDotnetApp = async (data) => {
    try {
        if ('email' in data.idToken.payload) {
          console.log('sending data dotnet app');
          const email = data["idToken"]["payload"]["email"]
          const accessLevel = data["idToken"]["payload"]["custom:AccessLevel"]
          const accessPrivilege = data["idToken"]["payload"]["custom:AccessPrivilege"]
          // Use fetch or any other method to send data to the .NET app
          const response = await fetch(callbackURL, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin' : 'https://www.refsys.kpitsmarttools.com',
                  'Access-Control-Allow-Credentials' : true,
                  'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS',
                  'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
              },
              body: JSON.stringify({ email, accessLevel, accessPrivilege })
          });
  
          if (response.ok) {
            // Handle successful login
            console.log('Data sent to .NET app successfully:');
          } else {
            // Handle login failure
            console.log('Failed to send data to .NET app');
          }
        } else {
          console.log('email key is not present.');
        }
    } catch (error) {
        console.error('Error sending data to .NET app:', error);
    }
  }

  return (
    <div className="login">
      <div className="login-header">
        <h2>Login with your credentials</h2>
        <div className="login-underline"></div>
      </div>
      <div className="login-body" style={{ flex: "80%" }}>
        <div className="login-inputs">
          <div
            className="login-input-box"
            style={{
              border: `2px solid ${email.length > 0 ? "#34dbc4" : "#f5eeee"}`,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-envelope fa-2x"
                aria-hidden="true"
                style={{
                  margin: "0px 30px",
                  fontSize: "1.5em",
                  color: "#acacac",
                }}
              ></i>
              <input
                type="text"
                required="required"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailAlert("");
                  setErrorMessage("");
                }}
              />
              <span>Email ID</span>
            </div>
            {emailAlert ? (
              <span style={{ color: "#ff0000" }}>{emailAlert}</span>
            ) : (
              <span />
            )}
          </div>
          <div
            className="login-input-box"
            style={{
              border: `2px solid ${
                password.length > 0 ? "#34dbc4" : "#f5eeee"
              }`,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-lock fa-2x"
                aria-hidden="true"
                style={{
                  margin: "0px 30px",
                  fontSize: "1.5em",
                  color: "#acacac",
                }}
              ></i>
              <input
                type="password"
                required="required"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setPasswordAlert("");
                  setErrorMessage("");
                }}
              />
              <span>password</span>
            </div>
            {passwordAlert ? (
              <span style={{ color: "#ff0000" }}>{passwordAlert}</span>
            ) : (
              <span />
            )}
          </div>
        </div>
        {errorMessage ? (
          <div className="login-notification">{errorMessage}</div>
        ) : (
          <div></div>
        )}
        <div
          className="login-forgot-password"
          style={{ margin: "15px", color: "#454649", fontSize: "18px" }}
        >
          Forgot Password?{" "}
          <span onClick={onSubmitForgotPassword}>Click here</span>
        </div>
        <div
          className="login-buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
            gap: "30px",
          }}
        >
          <button className="login-button effect" onClick={onSubmitLogin}>
            Login
          </button>
          <button className="signup-button effect" onClick={updateSignUpValue}>
            Signup
          </button>
        </div>
      </div>
      {modal ? (
        <div className="modal">
          <div className="overlay">
            <div className="modal-content">
              <p>User doesn't have access to login. please request admin to provide access</p>
              <button className="close-btn" onClick={toggleModle}>
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
