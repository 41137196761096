import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./../UserPool/UserPool";

const AccountContext = createContext();

const Account = (props) => {

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure", err);
          reject(err);
        }
      });
    });
  };

  return (
    <AccountContext.Provider value={{ authenticate }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
