import React, { useState } from "react";
import Login from "../Login/Login";
import FederatedLogin from "../Login/FederatedLogin";
import Signup from "../Signup/Signup";
import ForgotPassword from "./../ForgotPassword/ForgotPassword";
import { Account } from "./../AccountValidation/Account";
import './../LoginMain/LoginMain.css'

export default function LoginMain() {
  const [mainValue, setMainValue] = useState("0");

  const LoginValue = (value) => {
    setMainValue(value);
  };

  console.log("Signup child value check:", mainValue);
  return (
    <Account>
      <div
        className="login-main"
      >
        <div
          className="login-container"
        >
          {mainValue === "0" ? (
            <div style={{ display: "flex" }}>
              <div
                className="login-federated-main"
              >
                <FederatedLogin />
              </div>
              <div
                className="dividerline"
              ></div>
              <div
                className="login-submain"
                style={{ flex: "1", padding: "10px" }}
              >
                <Login sendValueToLoginMain={LoginValue} />
              </div>
            </div>
          ) : mainValue === "1" ? (
            <div className="Signup">
              <Signup sendValueToLoginMain={LoginValue} />
            </div>
          ) : (
            <div className="ForgotPassword">
              <ForgotPassword sendValueToLoginMain={LoginValue}/>
            </div>
          )}
        </div>
      </div>
    </Account>
  );
}
