import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  //Production cognito user pool
  UserPoolId: "ap-northeast-1_VohF2nMCt",
  ClientId: "132j0pfcfnm840qda2tba497nk",
  //old cognito user pool
  // UserPoolId: "ap-northeast-1_jKVUpPvY5",
  // ClientId: "58l2sdbndphgnj8v3jvjrk9spr",
  //DevTesting cognito user pool
  // UserPoolId: "ap-northeast-1_82ZDL9B60",
  // ClientId: "3okt4k9rdhtefqpf070c87si77",
};

console.log("user pool data: ", poolData)

export default new CognitoUserPool(poolData);
