// aws-exports.js
const awsconfig = {
  Auth: {
    region: "ap-northeast-1",
    // userPoolId: "ap-northeast-1_jKVUpPvY5",
    userPoolId: "ap-northeast-1_VohF2nMCt",
    userPoolWebClientId: "22388b53-89f5-49c0-a210-5b1f3892e2f3",
    // identityPoolId: "ap-northeast-1:d6e5c874-86fd-4ff2-8492-7ced6766664d",
    signUpVerificationMethod: "code", // 'code' | 'link'
    oauth: {
      domain:
        "login.microsoftonline.com/6e24bf88-1259-42f5-bdea-315b2c3aeb22",
      scope: ["openid", "profile", "email"],
      redirectSignIn:
        // "https://localhost:5001",
        // "https://refsys-measureinfopicker.kpitsmarttools.com",
        "https://measure-info-picker-alb-1171427977.ap-northeast-1.elb.amazonaws.com/",
        // "https://internal-measureinfo-picker-pvt-alb-1005251979.ap-northeast-1.elb.amazonaws.com",
        // "https://measure-info-picker-alb-1171427977.ap-northeast-1.elb.amazonaws.com",
      // "https://testloginauthenticationoidcfedreact.auth.ap-northeast-1.amazoncognito.com/oauth2/idpresponse",
      responseType: "code", // or 'token'
    },
  },
};

export default awsconfig;
