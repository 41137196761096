import React, { useState } from "react";
import UserPool from "../UserPool/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import './ForgotPassword.css';
import Alert from "@mui/material/Alert";

export default function ForgotPassword({ sendValueToLoginMain }) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [resetCodeAlert, setResetCodeAlert] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAlert, setNewPasswordAlert] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordAlert, setConfirmNewPasswordAlert] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetSuccessfulFlag, setResetSuccessfulFlag] = useState(0);

  const updateOriginalValue = () => {
    sendValueToLoginMain("0");
  };

  const onSubmitSendResetCode = async (event) => {
    console.log("signup");
    event.preventDefault();
    if (email === "") {
      setEmailAlert("Please enter Email ID");
    } else if (!email.includes("@kpit.com")) {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
    } else {
      const userData = {
        Username: email,
        Pool: UserPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          setStep(2);
        },
        onFailure: (err) => {
          console.log("Error sending reset code:", err);
          setErrorMessage(err);
        },
        inputVerificationCode: () => {
          console.log(" inputVerificationCode ");
          setStep(2);
        },
      });
    }
  };

  const handleConfirmationPassword = () => {
    if (resetCode === "" && newPassword === "" && confirmNewPassword === "") {
      setResetCodeAlert("Please enter reset code sent to email");
      setNewPasswordAlert("Please enter new password");
      setConfirmNewPasswordAlert("Please enter confirm password");
    } else if (
      (resetCode === "") & (newPassword !== "") &&
      confirmNewPassword === ""
    ) {
      setResetCodeAlert("Please enter reset code sent to email");
      setNewPasswordAlert("");
      setConfirmNewPasswordAlert("Please enter confirm password");
    } else if (
      (resetCode === "") & (newPassword === "") &&
      confirmNewPassword !== ""
    ) {
      setResetCodeAlert("Please enter reset code sent to email");
      setNewPasswordAlert("Please enter new password");
      setConfirmNewPasswordAlert("");
    } else if (
      (resetCode === "") & (newPassword !== "") &&
      confirmNewPassword !== ""
    ) {
      setResetCodeAlert("Please enter reset code sent to email");
      setNewPasswordAlert("");
      setConfirmNewPasswordAlert("");
    } else if (
      (resetCode !== "") & (newPassword === "") &&
      confirmNewPassword !== ""
    ) {
      setResetCodeAlert("");
      setNewPasswordAlert("Please enter new password");
      setConfirmNewPasswordAlert("");
    } else if (
      (resetCode !== "") & (newPassword !== "") &&
      confirmNewPassword === ""
    ) {
      setResetCodeAlert("");
      setNewPasswordAlert("");
      setConfirmNewPasswordAlert("Please enter confirm password");
    } else if (
      (resetCode !== "") & (newPassword === "") &&
      confirmNewPassword === ""
    ) {
      setResetCodeAlert("");
      setNewPasswordAlert("Please enter new password");
      setConfirmNewPasswordAlert("Please enter confirm password");
    } else {
      console.log("confirm password initiated");
      const userData = {
        Username: email,
        Pool: UserPool,
      };
      const cognitoUser = new CognitoUser(userData);

      if ((newPassword !== "") & (newPassword === confirmNewPassword)) {
        cognitoUser.confirmPassword(resetCode, newPassword, {
          onSuccess: (data) => {
            console.log("confirm password success", data);
            setResetSuccessfulFlag(1);
            setStep(1);
          },
          onFailure: (err) => {
            console.log("Error confirming password reset", err);
            setErrorMessage(err);
          },
        });
      } else {
        console.log("Please check the password policy. password do not match");
        setErrorMessage(
          "Please check the password policy. password do not match"
        );
      }
    }
  };

  return (
    <div
      className="forgot-password"
    >
      <div
        className="forgot-password-header"
      >
        <h2>Reset Password</h2>
        <div
          className="forgot-password-underline"
        ></div>
      </div>
      <div className="forgot-password-body" style={{ flex: "80%" }}>
        <div
          className="forgot-password-inputs"
        >
          {step === 1 ? (
            <div
              className="forgot-password-input-box"           
              style={{
                border: `2px solid ${email.length > 0 ? '#34dbc4' : '#f5eeee'}`
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className="fa fa-envelope fa-2x"
                  aria-hidden="true"
                  style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
                ></i>
                <input
                  type="text"
                  required="required"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setEmailAlert("");
                    setErrorMessage("");
                  }}
                />
                <span>Email ID</span>
              </div>
              {emailAlert ? (
                <span style={{ color: "#ff0000" }}>{emailAlert}</span>
              ) : (
                <span />
              )}
            </div>
          ) : (
            <div
              className="forgot-password-inputs"
            >
              <div
                className="forgot-password-input-box"
                style={{border: `2px solid ${resetCode.length > 0 ? '#34dbc4' : '#f5eeee'}`}}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className="fa fa-envelope fa-2x"
                    aria-hidden="true"
                    style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
                  ></i>
                  <input
                    type="text"
                    required= "required"
                    value={resetCode}
                    onChange={(event) => {
                      setResetCode(event.target.value);
                      setResetCodeAlert("");
                      setErrorMessage("");
                    }}
                  />
                  <span>Enter Reset Code</span>
                </div>
                {resetCodeAlert ? (
                  <span style={{ color: "#ff0000" }}>{resetCodeAlert}</span>
                ) : (
                  <span />
                )}
              </div>
              <div
                className="forgot-password-input-box"
                style={{border: `2px solid ${newPassword.length > 0 ? '#34dbc4' : '#f5eeee'}`}}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className="fa fa-lock fa-2x"
                    aria-hidden="true"
                    style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
                  ></i>
                  <input
                    type="password"
                    required= "required"
                    value={newPassword}
                    onChange={(event) => {
                      setNewPassword(event.target.value);
                      setNewPasswordAlert("");
                      setErrorMessage("");
                    }}
                  />
                  <span>Enter New Password</span>
                </div>
                {newPasswordAlert ? (
                  <span style={{ color: "#ff0000" }}>{newPasswordAlert}</span>
                ) : (
                  <span />
                )}
              </div>
              <div
                className="forgot-password-input-box"
                style={{border: `2px solid ${confirmNewPassword.length > 0 ? '#34dbc4' : '#f5eeee'}`}}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className="fa fa-lock fa-2x"
                    aria-hidden="true"
                    style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
                  ></i>
                  <input
                    type="password"
                    required= "required"
                    value={confirmNewPassword}
                    onChange={(event) => {
                      setConfirmNewPassword(event.target.value);
                      setConfirmNewPasswordAlert("");
                      setErrorMessage("");
                    }}
                  />
                  <span>Confirm New Password</span>
                </div>
                {confirmNewPasswordAlert ? (
                  <span style={{ color: "#ff0000" }}>
                    {confirmNewPasswordAlert}
                  </span>
                ) : (
                  <span />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={{ margin: "15px", display: "flex", justifyContent: "center" }}
        >
          {errorMessage ? (
            <div
              className="forgot-password-notification"
            >
              {errorMessage}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          className="forgot-password-buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
            gap: "30px",
          }}
        >
          <button
            className="forgot-password-back-button effect"
            onClick={updateOriginalValue}
          >
            Back
          </button>
          {step === 1 ? (
            <button className="forgot-password-reset-code-button effect"
              onClick={onSubmitSendResetCode}
            >
              Send Reset Code
            </button>
          ) : (
            <button className="forgot-password-reset-password-button effect"
              onClick={handleConfirmationPassword}
            >
              Reset Password
            </button>
          )}
        </div>
        {resetSuccessfulFlag === 1 ? (
          <div
            className="ForgetPassword-resetSuccessPopup"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Alert severity="success" onClose={updateOriginalValue}>
              Reset password successful
            </Alert>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
