import LoginMain from "./Components/LoginMain/LoginMain";
import "./App.css"

function App() {
  return (
    <div>
      <div className="background-image"></div>
      <div className="main-page">
        <h1>Reference System</h1>
        <LoginMain />
      </div>
    </div>
  );
}

export default App;
