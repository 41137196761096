import React, { useEffect, useState } from "react";
import UserPool from "./../UserPool/UserPool";
import Alert from "@mui/material/Alert";
import './Signup.css'


export default function Signup({ sendValueToLoginMain }) {
  const [userName, setUserName] = useState("");
  const [userNameAlert, setUserNameAlert] = useState("");
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAlert, setPasswordAlert] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordAlert, setConfirmPasswordAlert] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signupSuccessFlag, setSignupSuccessFlag] = useState("");

  useEffect(() => {
    updateUserName();
  })

  const updateOriginalValue = () => {
    sendValueToLoginMain("0");
  };

  const updateUserName = () => {
    if(setUserName !== ""){
      if(email.includes("@")){
        setUserName(email.split("@")[0]);
      } else {
        setUserName(email);
      }
    }
  }

  const onSubmitSignUp = (event) => {
    setErrorMessage("");
    console.log("signup");
    if (email === "" && password === "" && confirmPassword === "") {
      setEmailAlert("Please enter Email Id");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("Please confirm password")
    } else if (email === "" && password !== "" && confirmPassword === "") {
      setEmailAlert("Please enter Email Id");
      setConfirmPasswordAlert("Please confirm password")
      setPasswordAlert("");
    } else if (email === "" && password === "" && confirmPassword !== "") {
      setEmailAlert("Please enter Email Id");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("");
    } else if (email === "" && password !== "" && confirmPassword !== "") {
      setEmailAlert("Please enter Email Id");
      setPasswordAlert("");
      setConfirmPasswordAlert("");
    } else if (!email.includes("@kpit.com") && password === "" && confirmPassword === "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("Please confirm password");
    } else if (!email.includes("@kpit.com") && password !== "" && confirmPassword === "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("");
      setConfirmPasswordAlert("Please confirm password");
    } else if (!email.includes("@kpit.com") && password === "" && confirmPassword !== "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("");
    } else if (!email.includes("@kpit.com") && password !== "" && confirmPassword !== "") {
      setEmailAlert("Please enter Email Id in the format 'user@kpit.com'.");
      setPasswordAlert("");
      setConfirmPasswordAlert("");
    } else if (email.includes("@kpit.com") && password === "" && confirmPassword === "") {
      setEmailAlert("");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("Please confirm password");
    } else if (email.includes("@kpit.com") && password !== "" && confirmPassword === "") {
      setEmailAlert("");
      setPasswordAlert("");
      setConfirmPasswordAlert("Please confirm password");
    } else if (email.includes("@kpit.com") && password === "" && confirmPassword !== "") {
      setEmailAlert("");
      setPasswordAlert("Please enter password");
      setConfirmPasswordAlert("");
    } else {
      if ((password !== "") & (password === confirmPassword)) {
        console.log("same password");
        UserPool.signUp(userName, password, [{ Name: 'email', Value: email }], null, (err, data) => {
          if (err) {
            console.error("Error signing up:", err);
            setErrorMessage(err.message);
          } else {
            console.log("Sign up successful:", data);
            setSignupSuccessFlag(1);
          }
        });
      } else {
        console.log("diff password");
        setErrorMessage(
          "Please check the password policy. password do not match"
        );
      }
    }
  };

  return (
    <div
      className="signup"
    >
      <div
        className="signup-header"
      >
        <h2>Sign up with a new account</h2>
        <div
          className="signup-underline"
        ></div>
      </div>
      <div className="signup-body" style={{ flex: "80%" }}>
        <div
          className="signup-inputs"
        >          
          <div
            className="signup-input-box"            
            style={{
              border: `2px solid ${email.length > 0 ? '#34dbc4' : '#f5eeee'}`
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-envelope fa-2x"
                aria-hidden="true"
                style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
              ></i>
              <input
                type="text"
                required="required"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailAlert("");
                  setErrorMessage("");
                }}
              />
              <span>Email ID</span>
            </div>
            {emailAlert ? (
              <span style={{ color: "#ff0000" }}>{emailAlert}</span>
            ) : (
              <span />
            )}
          </div>
          <div
            className="signup-input-box"            
            style={{
              border: `2px solid ${userName.length > 0 ? '#34dbc4' : '#f5eeee'}`
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-user fa-2x"
                aria-hidden="true"
                style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
              ></i>
              <input
                type="text"
                required="required"
                value={userName}
                onChange={updateUserName}
              />
              <span>User Name</span>
            </div>
            {userNameAlert ? (
              <span style={{ color: "#ff0000" }}>{userNameAlert}</span>
            ) : (
              <span />
            )}
          </div>
          <div
            className="signup-input-box"
            style={{border: `2px solid ${password.length > 0 ? '#34dbc4' : '#f5eeee'}`}}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-lock fa-2x"
                aria-hidden="true"
                style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
              ></i>
              <input
                type="password"
                required= "required"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setPasswordAlert("");
                  setErrorMessage("");
                }}
              />
              <span>password</span>
            </div>
            {passwordAlert ? (
              <span style={{ color: "#ff0000" }}>{passwordAlert}</span>
            ) : (
              <span />
            )}
          </div>
          <div
            className="signup-input-box"
            style={{border: `2px solid ${confirmPassword.length > 0 ? '#34dbc4' : '#f5eeee'}`}}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa fa-lock fa-2x"
                aria-hidden="true"
                style={{ margin: "0px 30px", fontSize: "1.5em", color: "#acacac" }}
              ></i>
              <input
                type="password"
                required= "required"
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  setConfirmPasswordAlert("");
                  setErrorMessage("");
                }}
              />
              <span>confirm password</span>
            </div>
            {confirmPasswordAlert ? (
              <span style={{ color: "#ff0000" }}>{confirmPasswordAlert}</span>
            ) : (
              <span />
            )}
          </div>
        </div>
        <div
          style={{ margin: "15px", display: "flex", justifyContent: "center" }}
        >
          {errorMessage ? (
            <div
              className="signup-notification"
            >
              {errorMessage}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          className="signup-buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
            gap: "30px",
          }}
        >
          <button className="signup-back-button effect"
            onClick={updateOriginalValue}
          >
            Back
          </button>
          <button className="signup-ok-button effect"
            onClick={onSubmitSignUp}
          >
            Signup
          </button>
        </div>
        {signupSuccessFlag === 1 ? (
          <div
            className="Signup-SuccessPopup"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Alert severity="success" onClose={updateOriginalValue}>
              Signup successful
            </Alert>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
