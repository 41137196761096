import azure_icon from "./../Images/azure_ad.png";
import { Auth } from "aws-amplify";
import './Login.css'


export default function FederatedLogin() {
  const signInWithAzure = async (provider) => {
    console.log("Signed in with Azure.");
    try {
      await Auth.federatedSignIn({provider: 'OIDC'});
      console.log('Signed in with Auth.federatedSignIn');
    } catch (error) {
      console.error(
        "Error signing in with federated identity provider:",
        error
      );
      console.log("Current authentication state:", Auth.user);
    }
  };
  return (
    <div
      className="federated-login"
    >
      <div
        className="federated-login-header"
      >
        <h2>Signin with corporate ID</h2>
        <div
          className="signup-underline"
        ></div>
      </div>
      <div className="federated-login-body" >
          <button className="federated-login-button"
            onClick={signInWithAzure}
          >
            <img
              src={azure_icon}
              alt=""
              style={{ height: "25px", padding: "7px" }}
            ></img>
            <div style={{ padding: "11px", fontSize: "14px" }}>Signin With Microsoft Azure</div>
          </button>
      </div>
    </div>
  );
}
